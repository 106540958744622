@import 'react-datepicker/dist/react-datepicker.css';
@import './fonts.css';
* {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  /*transition: all 0.1s;*/
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea,
menu {
  margin: 0;
  padding: 0;
}
header,
footer,
section,
article,
aside,
nav,
hgroup,
address,
figure,
figcaption,
menu,
details {
  display: block;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th {
  text-align: left;
  font-weight: normal;
}
html,
body,
fieldset,
img,
iframe,
abbr {
  border: 0;
}
i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}
[hidefocus],
summary {
  outline: 0;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
small {
  font-size: 100%;
}
sup,
sub {
  font-size: 83%;
}
pre,
code,
kbd,
samp {
  font-family: inherit;
}
q:before,
q:after {
  content: none;
}
textarea {
  overflow: auto;
  resize: none;
}
label,
summary {
  cursor: default;
}
a,
button {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-weight: bold;
}
del,
ins,
u,
s,
a,
a:hover {
  text-decoration: none;
}
body,
textarea,
input,
button,
select,
keygen,
legend {
  background-color: unset;
  outline: 0;
  border: 0;
  font-size: var(--affine-font-base);
  line-height: var(--affine-line-height);
  font-family: var(--affine-font-family);
}
body {
  background: transparent;
  overflow: hidden;
}

input {
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none; /*Solve the rounded corners of buttons on ios*/
  border-radius: 0; /*Solve the problem of rounded corners of the input box on ios*/
  outline: medium; /*Remove the default yellow border on mouse click*/
  background-color: transparent;
  caret-color: var(--affine-primary-color);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
::-webkit-scrollbar {
  width: 0; /* Chrome Safari */
  height: 0;
}
.affine-default-viewport::-webkit-scrollbar {
  width: 20px; /* Chrome Safari */
  height: 20px;
}
.affine-default-viewport::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-clip: padding-box;
  border-style: solid;
  border-width: 7px;
  border-color: transparent;
  transition: all 0.2s;
}
.affine-default-viewport:hover::-webkit-scrollbar-thumb {
  background-color: var(--affine-divider-color);
}
.affine-default-viewport:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--affine-icon-color);
  border-width: 5px;
}
.editor-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  padding-right: 0;
}

/* issue: https://github.com/toeverything/AFFiNE/issues/2004 */
.editor-wrapper.edgeless-mode {
  padding: 0;
}

.affine-default-page-block-title-container {
  margin-top: 40px;
  margin-bottom: 40px;
  transition: margin-top 0.2s;
}

.affine-default-page-block-container {
  transition: max-width 0.2s;
  min-width: 550px;
}

affine-block-hub {
  position: unset !important;
}
.block-hub-menu-container {
  position: unset !important;
}

@media (max-width: 768px) {
  .affine-default-page-block-title-container {
    margin-top: 24px;
  }

  .editor-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 0.75rem;
  }

  /* issue: https://github.com/toeverything/AFFiNE/issues/2004 */
  .editor-wrapper.edgeless-mode {
    padding: 0;
  }
}
